import React from "react"
import { ThemeContext } from "../context/themeContext"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const ThemeToggle = ({ className }) => {
  const { theme, setTheme } = React.useContext(ThemeContext)

  function isLight() {
    return theme === "light"
  }

  const ToggleTheme = _ => {
    setTheme(isLight() ? "dark" : "light")
    trackCustomEvent({
      category: "Dark Mode Toggle",
      action: "Click",
      label: "Dark Mode",
    })
  }

  const getToggleClassName = _ => {
    return `${className} ${
      isLight() ? "rotate-0" : "rotate-180"
    } focus:outline-none`
  }

  return (
    <>
      <button
        aria-label={isLight() ? "Activate Dark Mode" : "Activate Light Mode"}
        title={isLight() ? "Activate Dark Mode" : "Activate Light Mode"}
        onClick={ToggleTheme}
        className={getToggleClassName()}
      >
        <svg
          aria-hidden="true"
          className="w-6 h-6 md:w-5 md:h-5 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          {isLight() ? (
            <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
          ) : (
            <path
              d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
              fill-rule="evenodd"
              clip-rule="evenodd"
            />
          )}
        </svg>
      </button>
    </>
  )
}

export default ThemeToggle

import { Link } from "gatsby"
import React from "react"
import ThemeToggle from "./themeToggle"
import { NavLink } from "./atoms"

const Header = ({ activePage }) => (
  <header className="container m-auto px-5 sm:px-12 md:px-20 max-w-screen-xl h-32 overflow-hidden">
    <nav
      className="mt-auto h-full flex space-x-6 items-center justify-start text-sm"
      aria-label="Main Navigation"
    >
      <Link to="/" aria-label="Website logo, go back to homepage.">
        <svg
          aria-hidden="true"
          role="img"
          className="h-8 w-8 fill-current hover:text-accent transition duration-150"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 530 373"
        >
          <path d="M195 406 l-50 -71 -3 68 -3 67 -48 0 -49 0 -6 -127 c-3 -71 -9 -172 -12 -225 l-6 -98 78 0 79 1 35 76 35 75 38 -76 37 -76 70 0 69 0 -5 213 c-3 116 -7 218 -10 225 -2 7 -21 12 -48 12 -50 0 -56 -10 -56 -90 l0 -45 -48 71 -47 72 -50 -72z"/>
        </svg>
      </Link>
      <span className="hidden sm:flex flex-grow items-center justify-end space-x-6">
        <NavLink to="/" title="Home" selected={activePage === "/"}>
          Home
        </NavLink>
        {/* Disable this for now.
        <NavLink to="/about" title="About" selected={activePage === "about"}>
          About
        </NavLink>
        */}
      </span>
      <ThemeToggle className="hidden sm:block hover:text-accent transition duration-150" />
    </nav>
  </header>
)

export default Header

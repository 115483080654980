import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

const TagsLayout = ({ pageContext, data }) => {
  const { tag } = pageContext
  return (
    <Layout activePage="tags">
      <section className="mb-16">
        <h2 class="text-2xl font-bold text-accent">Tag - "{tag}"</h2>
        <span>A collection of {data.allMdx.totalCount} related posts.</span>
        <ul className="mt-6 divide-y divide-subtle">
          {data.allMdx.edges.map(({ node }) => {
            const post = {
              slug: node.fields.slug,
              title: node.frontmatter.title,
              date: node.frontmatter.date,
              description: node.frontmatter.description,
              tags: node.frontmatter.tags,
            }
            return (
              <li className="py-3">
                <Link to={post.slug}>
                  <div className="-mx-5 -my-3 px-5 py-3 group flex flex-col sm:flex-row sm:justify-between sm:items-end hover:bg-secondary">
                    <div>
                      <h3 className="text-xl font-semibold group-hover:text-accent">
                        {post.title}
                      </h3>
                      <h4 className="font-medium text-tertiary">
                        {post.description}
                      </h4>
                    </div>
                    <div className="mt-2 sm:mt-0 text-sm sm:text-base text-accent sm:text-tertiary">
                      {post.date}
                    </div>
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { published: { eq: true }, tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM Do YYYY")
            published
            tags
            description
          }
          fields {
            slug
          }
        }
      }
      totalCount
    }
  }
`

export default TagsLayout

import React from "react"
import PropTypes from "prop-types"

import { ThemeProvider } from "../context/themeContext"
import Header from "./header"
import MobileNav from "./mobileNav"
import Footer from "./footer"

const Layout = ({ children, activePage }) => {
  return (
    <>
      <ThemeProvider>
        <MobileNav />
        <div className="flex flex-col min-h-screen">
          <Header activePage={activePage} />
          <main className="container flex-grow max-w-screen-xl m-auto px-5 sm:px-12 md:px-20">
            {children}
          </main>
          <Footer />
        </div>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
